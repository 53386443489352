


















































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import InformationBox from "@/components/InformationBox.vue";

@Component({
  components: {
    InformationBox,
  },
})
export default class AnalyzerAddEdit extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public analyzer: any = {
    calibration: {},
  };

  get comp_temp_values() {
    const value = this.analyzer.calibration.comp_temp_values;
    return JSON.stringify(value);
  }

  set comp_temp_values(entry: string) {
    try {
      this.analyzer.calibration.comp_temp_values = JSON.parse(entry);
    } catch (e) {
      return;
    }
  }

  public async created() {
    if (this.id !== null) {
      this.$store
        .dispatch("loadAnalyzer", { analyzerId: this.id })
        .then((response: any) => {
          this.analyzer = response;
          this.loading = false;
        })
        .catch((error: any) => {
          this.globalError(error);
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private onSubmit() {
    this.loading = true;
    const payload = {
      analyzerId: this.analyzer.id,
      payload: {
        calibration: this.analyzer.calibration,
      },
    };

    this.$store
      .dispatch("editAnalyzer", payload)
      .then((response: any) => {
        this.$q.notify({
          message: `La calibration ${response.serial} a été sauvegardé`,
          color: "teal",
        });
        this.$router.push(`/admin/analyzer/${response.id}`);
        this.loading = false;
      })
      .catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
  }

  private validateJSON(data: any) {
    try {
      JSON.parse(data);
      return true;
    } catch (e) {
      return false;
    }
  }

  private fillCompValueAuto() {
    this.comp_temp_values = '{"method": "exprg2-auto"}';
  }
  private fillCompValueCustom() {
    this.comp_temp_values = '{"method": "exprg2", "offset": 0, "popt": [0, 0, 0]}';
  }
}
